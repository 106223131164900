import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`useOnOutsideClick`}</inlineCode>{` is a utility Hook that calls a user-provided callback function when the user clicks outside of the provided container.`}</p>
    <p>{`You can also pass an array of `}<inlineCode parentName="p">{`ignoredRefs`}</inlineCode>{` to prevent calling the callback function on additional elements on the page. This can be handy for ignoring clicks on trigger buttons that already manage the open/closed state of content.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State>
  {([isOpen, setIsOpen]) => {
    const containerRef = React.useRef(null)
    const triggerRef = React.useRef(null)

    const closeOverlay = React.useCallback(() => {
      setIsOpen(false)
    }, [setIsOpen])

    const toggleOverlay = React.useCallback(() => {
      setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    useOnOutsideClick({onClickOutside: closeOverlay, containerRef, ignoreClickRefs: [triggerRef]})

    return (
      <>
        <Button ref={triggerRef} onClick={toggleOverlay}>
          toggle
        </Button>
        {isOpen && (
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor="border.default"
            borderRadius={2}
            height="200px"
            bg="green.4"
            ref={containerRef}
          >
            content
          </Box>
        )}
      </>
    )
  }}
</State>
`}</code></pre>
    <h4>{`useOnOutsideClick settings`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onOutsideClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call when user clicks outside of the container. Usually this manages the state of the visibility of the container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ignoredRefs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement> []`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Elements outside of the container to ignore clicks on.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`containerRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required. A ref for the containing element.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      